<template>
  <div>
    <!-- filter -->
    <b-card
      no-body
      class="mb-1"
    >
      <b-card-header class="py-1">
        <h4 class="card-title font-weight-bolder">
          {{ $t('filters') }}
        </h4>
        <div class="d-flex align-items-center justify-content-end">
          <!-- ANCHOR Button Reload -->
          <b-button
            variant="primary"
            class="mr-1"
            @click="fetch()"
          >
            <span class="text-nowrap">
              <feather-icon
                class="cursor-pointer"
                icon="RefreshCcwIcon"
                size="16"
              />
              {{ $t('refresh') }}
            </span>
          </b-button>
          <!-- ANCHOR Button Search -->
          <b-button
            variant="info"
            class="mr-1"
            @click="fetch()"
          >
            <span class="text-nowrap">
              <feather-icon
                class="cursor-pointer"
                icon="SearchIcon"
                size="16"
              />
              {{ $t('search') }}
            </span>
          </b-button>

          <!-- ANCHOR Button Clear Search Filters -->
          <b-button
            variant="danger"
            @click="fetch(true)"
          >
            <span class="text-nowrap">
              <feather-icon
                class="cursor-pointer"
                icon="XOctagonIcon"
                size="16"
              />
              {{ $t('clear') }}
            </span>
          </b-button>
        </div>
      </b-card-header>

      <b-card-body class="py-0">
        <b-container fluid>
          <b-row>
            <b-col md="4">
              <b-form-group
                label-class="h5 py-0"
                :label="$t('sepay.search.key')"
              >
                <b-form-input
                  v-model.trim="filter.q"
                  :placeholder="$t('sepay.search.transaction')"
                  debounce="500"
                />
              </b-form-group>
            </b-col>

            <!-- ANCHOR START DATE -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label-class="h5 py-0"
                :label="$t('topup.startDate')"
              >
                <b-form-datepicker
                  v-model="filter.startTransactionDate"
                  style="font-size: 1rem;"
                  :today-button="!filter.startTransactionDate"
                  reset-button
                  :max="maxStartDate"
                  close-button
                  :locale="$i18n.locale"
                  :placeholder="$t('topup.placeholderSelectDate')"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                />
              </b-form-group>
            </b-col>

            <!-- ANCHOR END DATE -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label-class="h5 py-0"
                :label="$t('topup.endDate')"
              >
                <b-form-datepicker
                  v-model="filter.endTransactionDate"
                  style="font-size: 1rem;"
                  today-button
                  reset-button
                  close-button
                  :locale="$i18n.locale"
                  :min="minEndDate"
                  :max="today"
                  :placeholder="$t('topup.placeholderSelectDate')"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                />
              </b-form-group>
            </b-col>

            <!-- <b-col md="4">
              <b-form-group
                label-class="h5 py-0"
                :label="$t('sepay.columns.transferType')"
              >
                <v-select
                  v-model="filter.status"
                  :placeholder="$t('sepay.columns.transferType')"
                  :options="transferTypes"
                  :clearable="false"
                >
                  <template #option="data">
                    <span>
                      {{ $t('sepay.status.' + data.label) }}
                    </span>
                  </template>

                  <template #selected-option="data">
                    <span>
                      {{ $t('sepay.status.' + data.label) }}
                    </span>
                  </template>

                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col> -->
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>

    <!-- table -->
    <b-card no-body>
      <b-overlay
        :show="loading"
        rounded="sm"
        no-fade
        variant="transparent"
        :opacity="0.5"
      >
        <b-table
          ref="refDataTable"
          bordered
          no-border-collapse
          sticky-header
          responsive
          show-empty
          primary-key="id"
          style="max-height: 70vh"
          class="position-relative"
          table-class="table-resell-ticket"
          :items="items"
          :fields="tableColumns"
          :empty-text="$t('noRecordFund')"
          :sort-by.sync="filter.sortBy"
          :sort-desc.sync="filter.isSortDirDesc"
        >
          <!-- ANCHOR Header -->
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]="data"
          >
            <div
              :key="column.label"
              class="text-dark text-nowrap text-center"
            >
              <template>
                {{ $t(`sepay.columns.${data.label}`) }}
              </template>
            </div>
          </template>

          <template
            v-for="column in tableColumns"
            #[`cell(${column.key})`]="{ item }"
          >
            <div
              :key="column.key"
              class="text-nowrap"
              :class="{
                'text-right': typeof item[column.key] === 'number',
              }"
            >
              {{ item[column.key] }}
            </div>
          </template>

          <!-- ANCHOR stt. -->
          <template #cell(stt)="data">
            <span class="text-right">
              {{ data.index + 1 }}</span>
          </template>

          <!-- ANCHOR createdAt. -->
          <template #cell(transactionDate)="{ item }">
            <span class="text-right">
              {{ convertISODateTime(item.transactionDate, 0).dateTime }}
            </span>
          </template>

          <!-- Column: PAYMENT CODE -->
          <template #cell(paymentCode)="{ item }">
            <b-link
              v-if="item.paymentCode"
              :to="{ name: 'apps-topup-view-code', params: { code: item?.paymentCode } }"
              class="font-weight-bold d-block text-nowrap text-info pb-0"
            >
              {{ item.paymentCode }}
            </b-link>
          </template>
          <!-- ANCHOR lastTransaction. -->
          <template #cell(status)="{ item }">
            <span class="text-right">
              {{ $t(`sepay.status.${item.status}`) }}
            </span>
          </template>

          <!-- ANCHOR transferType. -->
          <template #cell(transferType)="{ item }">
            <span class="text-right">
              {{ $t(`sepay.status.${item.transferType}`) }}
            </span>
          </template>

          <!-- ANCHOR amount. -->
          <template #cell(amount)="{ item }">
            <span class="text-right">
              {{ formatVnCurrency(item.amount) }}
            </span>
          </template>

          <!-- ANCHOR companyId. -->
          <template #cell(company)="{ item }">
            <span class="text-right">
              {{ resoleCompany(item) }}
            </span>
          </template>

          <!-- ANCHOR bankId. -->
          <template #cell(bankId)="{ item }">
            <span class="d-flex gap-1 align-items-center">
              <BImg
                :src="resoleBank(item.bankId).logo_path"
                width="30"
              />
              <div>{{ resoleBank(item.bankId).short_name }} </div>
            </span>
          </template>

          <!-- ANCHOR paymentBankAccountId. -->
          <template #cell(paymentBankAccountId)="{ item }">
            <span class="text-right">
              {{ resoleBankAccount(item.paymentBankAccountId) }}
            </span>
          </template>
        </b-table>
      </b-overlay>
      <!-- paging -->
      <IAmPaging
        :page="filter.page"
        :size="filter.perPage"
        :total="total"
        :refDataTable="refDataTable"
        @set-page="(val)=>filter.page = val"
        @set-size="(val)=>filter.perPage = val"
      />
    </b-card>

    <!-- embed -->
  </div>
</template>

<script>
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api'
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BContainer,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BImg,
  BLink,
  BOverlay,
  BRow,
  BTable,
} from 'bootstrap-vue'
import debounce from 'lodash/debounce'

import IAmPaging from '@/components/IAmPaging.vue'
import {
  sizePerPageLgOptions,
} from '@/constants/selectOptions'

import {
  convertISODateTime,
  formatVnCurrency,
} from '@core/utils/filter'

import useSepayHandle from '@sepay/useSepayHandle'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    IAmPaging,
    BContainer,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BFormDatepicker,
    BLink,
    BImg,
  },
  setup() {
    //
    const {
      loading,
      transactions: items,
      filterTransaction: filter,
      totalTransaction: total,
      fetchTransactions: fetch,
      companies,
      filterCompany,
      fetchCompanies,
    } = useSepayHandle()

    const transferTypes = ref(['debit', 'credit'])

    const refDataTable = ref(null)
    const tableColumns = ref([
      { key: 'stt', label: 'stt' },
      { key: 'company', label: 'agency', class: 'text-nowrap' },
      { key: 'bankId', label: 'bankId' },
      // { key: 'paymentBankAccountId', label: 'paymentBankAccountId' },
      { key: 'bankAccountName', label: 'bankAccountName' },
      { key: 'accountNumber', label: 'accountNumber' },
      { key: 'accountNumber', label: 'accountNumber' },
      { key: 'paymentCode', label: 'paymentCode' },
      { key: 'amount', label: 'amount' },
      // { key: 'vaId', label: 'vaId' },
      // { key: 'va', label: 'va' },
      { key: 'transactionContent', label: 'transactionContent' },
      { key: 'transferType', label: 'transferType' },
      { key: 'referenceNumber', label: 'referenceNumber' },
      { key: 'transactionId', label: 'transactionId' },
      { key: 'transactionDate', label: 'transactionDate' },

    ])

    const searchCompany = debounce(search => {
      filterCompany.value.q = search
      fetchCompanies()
    }, 300)
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const maxStartDate = computed(() => (filter.endTransactionDate ? filter.endTransactionDate : today))
    const minEndDate = computed(() => (filter.startTransactionDate ? filter.startTransactionDate : undefined))

    function resoleCompany(item) {
      return `${item?.agency?.agencyName} (${item?.agency?.agencyCode})`
    }
    function resoleBank(bankId) {
      const banks = [
        {
          id: '3',
          brand_name: 'ACB',
          full_name: 'Ngân hàng TMCP Á Châu',
          short_name: 'ACB',
          code: 'ACB',
          logo_path: 'https://my.sepay.vn/assets/images/banklogo/acb.png',
        },
        {
          id: '8',
          brand_name: 'MBBank',
          full_name: 'Ngân hàng TMCP Quân Đội',
          short_name: 'MBBank',
          code: 'MB',
          logo_path: 'https://my.sepay.vn/assets/images/banklogo/mbbank.png',
        },
        {
          id: '18',
          brand_name: 'OCB',
          full_name: 'Ngân hàng TMCP Phương Đông',
          short_name: 'OCB',
          code: 'OCB',
          logo_path: 'https://my.sepay.vn/assets/images/banklogo/ocb.png',
        },
      ]
      const bank = banks.find(e => e.id === bankId)
      return bank
    }
    function resoleBankAccount(id) {
      return id
    }

    let intervalId

    onMounted(() => {
      intervalId = setInterval(fetch, 120000) // 2 minutes
      fetch()
    })

    onUnmounted(() => {
      clearInterval(intervalId) // Dừng khi component bị destroy
    })
    return {
      transferTypes,
      refDataTable,
      loading,
      filter,
      items,
      total,
      tableColumns,
      fetch,
      today,
      maxStartDate,
      minEndDate,
      companies,
      searchCompany,
      resoleCompany,
      resoleBank,
      resoleBankAccount,
      // import
      sizePerPageLgOptions,
      convertISODateTime,
      formatVnCurrency,
    }
  },
}
</script>
